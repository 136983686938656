<template>
  <div id="PainelCliente" class="container-fundo" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
      <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">

          <v-toolbar-title class="text-white title-page mt-1 ml-1 w-100">
            <router-link :to="{ name: 'Painel' }" class="nav-link ml-3 mt-1" aria-current="page">
            <v-btn icon dark color="grey" class="btn-icon mr-1">
              <v-icon color="#84A0CD" class="icon-action"> mdi-chevron-left </v-icon>
            </v-btn>
          </router-link>
            Área do Cliente
          </v-toolbar-title>

        <v-toolbar-title
          class="mt-0 mb-15 ml-4 pa-0 d-flex flex-column w-100"
          dark
          elevation="0"
        >
          <div class="d-flex align-center w-100" tabindex="-1">
          </div>
        </v-toolbar-title>

      </v-toolbar-title>

    <!-- Container Painel -->
    <v-container class="container-main pt-0 px-0 mt-n11">
      <v-card class="card d-flex" elevation="0">
        <v-card-text class="pt-4 px-4 mb-7">
          <!-- Container Cards -->
          <v-row class="row d-flex flex-row">
            <!-- <v-col  sm="12" md="12" lg="12"> -->
              <!-- <v-card class="card-panel pa-1 pb-2" style="background-color: #F5D5D5;" elevation="0"> -->
                <!-- <router-link
                  :to="'item.componente'"
                  exact
                  class="nav-link font-weight-regular no-underline"
                  aria-current="page"> -->
                <!-- <v-card-title class="pa-2 pt-0" style="font-size: 16px; color: #1976d2;">
                  <strong>Pendências e Notificações</strong>
                </v-card-title> -->

                <!-- <v-row>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                    <router-link
                      :to="'item.componente'"
                      exact
                      class="nav-link font-weight-regular no-underline"
                      aria-current="page">
                      <v-card class="mx-2 d-flex justify-space-between" style="background-color: #EB8181;" elevation="0">
                        <div>
                          <v-card-text style="color: white; font-size: 14px;" class="pb-0 pt-1 pl-2"><strong>Documentos Pendentes</strong></v-card-text>
                          <v-card-text style="font-size: 10px; color: white;" class="pt-0 pl-2 pb-1">Clique aqui para enviar os documentos</v-card-text>
                        </div>
                        <span class="pr-2 pt-3" style="color: white; font-size: 18px;">3</span>
                      </v-card>
                    </router-link>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-card class="mx-2 d-flex justify-space-between" style="background-color: #EB8181;" elevation="0">
                      <div>
                        <v-card-text style="color: white; font-size: 14px;" class="pb-0 pt-1 pl-2"><strong>Documentos Pendentes</strong></v-card-text>
                        <v-card-text style="font-size: 10px; color: white;" class="pt-0 pl-2 pb-1">Clique aqui para enviar os documentos</v-card-text>
                      </div>
                      <span class="pr-2 pt-3" style="color: white; font-size: 18px;">3</span>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-card class="mx-2 d-flex justify-space-between" style="background-color: #EB8181;" elevation="0">
                      <div>
                        <v-card-text style="color: white; font-size: 14px;" class="pb-0 pt-1 pl-2"><strong>Documentos Pendentes</strong></v-card-text>
                        <v-card-text style="font-size: 10px; color: white;" class="pt-0 pl-2 pb-1">Clique aqui para enviar os documentos</v-card-text>
                      </div>
                      <span class="pr-2 pt-3" style="color: white; font-size: 18px;">3</span>
                    </v-card>
                  </v-col>
                </v-row> -->
                <!-- </router-link> -->
              <!-- </v-card> -->
            <!-- </v-col> -->
            <v-col  sm="12" md="12" lg="12">
              <v-card class="card-panel pa-1 pb-2" elevation="0">
                <!-- <router-link
                  :to="'item.componente'"
                  exact
                  class="nav-link font-weight-regular no-underline"
                  aria-current="page"> -->
                <v-card-title class="pa-2 pt-0" style="font-size: 16px; color: #1976d2;">
                  <strong>Financeiro</strong>
                </v-card-title>

                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <router-link
                      :to="'/extratofinanceiro'"
                      exact
                      class="nav-link font-weight-regular no-underline"
                      aria-current="page">
                      <v-card class="mx-2" style="background-color: #70A962;" elevation="0"> 
                        <div class="d-flex align-baseline">
                          <v-icon color="white" small class="ml-2">mdi-finance</v-icon>
                          <v-card-text style="color: white; font-size: 14px;" class="pb-0 pt-1 pl-2"><strong>Extrato Financeiro</strong></v-card-text>
                        </div>
                        <v-card-text style="font-size: 10px; color: white;" class="pt-0 pl-2 pb-1">Clique aqui para extrair seu extrato financeiro das parcelas de sua unidade</v-card-text>
                      </v-card>
                    </router-link>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <router-link
                      :to="'/segundavia'"
                      exact
                      class="nav-link font-weight-regular no-underline"
                      aria-current="page">
                      <v-card class="mx-2" style="background-color: #41e87a;" elevation="0">
                        <div class="d-flex align-baseline">
                          <v-icon color="white" small class="ml-2">mdi-account-cash</v-icon>
                          <v-card-text style="color: white; font-size: 14px;" class="pb-0 pt-1 pl-2"><strong>Segunda Via de Boletos</strong></v-card-text>
                        </div>
                        <v-card-text style="font-size: 10px; color: white;" class="pt-0 pl-2 pb-1">Clique aqui para emitir segunda via do boleto quando estiver liberado para impressão</v-card-text>
                      </v-card>
                    </router-link>
                  </v-col>
                  <!-- <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-card class="mx-2" style="background-color: #81EBA5;" elevation="0">
                      <v-card-text style="color: white; font-size: 14px;" class="pb-0 pt-1 pl-2"><strong>Documentos Pendentes</strong></v-card-text>
                      <v-card-text style="font-size: 10px; color: white;" class="pt-0 pl-2 pb-1">Clique aqui para enviar os documentos</v-card-text>
                    </v-card>
                  </v-col> -->
                </v-row>
                <!-- </router-link> -->
              </v-card>
            </v-col>
            <!-- <v-col  sm="12" md="12" lg="12"> -->
              <!-- <v-card class="card-panel pa-1 pb-2" elevation="0"> -->
                <!-- <router-link
                  :to="'item.componente'"
                  exact
                  class="nav-link font-weight-regular no-underline"
                  aria-current="page"> -->
                <!-- <v-card-title class="pa-2 pt-0" style="font-size: 16px; color: #1976d2;">
                  <strong>Perfil</strong>
                </v-card-title> -->

                <!-- <v-row>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                    <router-link
                      :to="'item.componente'"
                      exact
                      class="nav-link font-weight-regular no-underline"
                      aria-current="page">
                      <v-card class="mx-2" style="background-color: #70A962;" elevation="0">
                        <v-card-text style="color: white; font-size: 14px;" class="pb-0 pt-1 pl-2"><strong>Meus Dados</strong></v-card-text>
                        <v-card-text style="font-size: 10px; color: white;" class="pt-0 pl-2 pb-1">Clique aqui para visualizar</v-card-text>
                      </v-card>
                    </router-link>
                  </v-col> -->
                  <!-- <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-card class="mx-2" style="background-color: #81EBA5;" elevation="0">
                      <v-card-text style="color: white; font-size: 14px;" class="pb-0 pt-1 pl-2"><strong>Documentos Pendentes</strong></v-card-text>
                      <v-card-text style="font-size: 10px; color: white;" class="pt-0 pl-2 pb-1">Clique aqui para enviar os documentos</v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                    <v-card class="mx-2" style="background-color: #81EBA5;" elevation="0">
                      <v-card-text style="color: white; font-size: 14px;" class="pb-0 pt-1 pl-2"><strong>Documentos Pendentes</strong></v-card-text>
                      <v-card-text style="font-size: 10px; color: white;" class="pt-0 pl-2 pb-1">Clique aqui para enviar os documentos</v-card-text>
                    </v-card>
                  </v-col> -->
                <!-- </v-row> -->
                <!-- </router-link> -->
              <!-- </v-card> -->
            <!-- </v-col> -->
          </v-row>
       
          <!-- Fim Container Cards -->
        </v-card-text>
      </v-card>
    </v-container>
    <!-- Fim Container Painel -->

  </div>
</template>

<script>
// import store_usuario from "../store/store_usuario";
import store_usuario from "../../../store/store_usuario";
// import { COR_PRINCIPAL, COR_SECUNDARIA } from "../services/constantes";
import { COR_PRINCIPAL, COR_SECUNDARIA } from "../../../services/constantes";

export default {
  name: "PainelCliente",
  components: {},

  data() {
    return {
      store_usuario   : store_usuario,
      COR_PRINCIPAL   : COR_PRINCIPAL,
      COR_SECUNDARIA  : COR_SECUNDARIA
    }
  },

  created() {
  },

  destroyed() {
  },

  methods: {
    abre(cod_funcao) {
    },

    changeColor(variable, color) {
      document.documentElement.style.setProperty(variable, color);
    }

  },

};
</script>

<style scoped>
  /* >>> {
    --accent-color: #c1d32f;
  } */

  .container {
    padding-bottom: 0!important;
  }
  .main-title {
    background: #0548a3;
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .title-page {
    max-width: 600px !important;
    opacity: 0.87;
  }

  .container-main {
    max-width: 100%;
  }

  @media (max-width: 599px) {
    .container-main {
      max-width: 100% !important;
    }
  }

  .card {
    border-radius: 8px;
    background: var(--COR_SECUNDARIA) !important;
    min-height: calc(100vh - 116px) !important;
  }

  .h-100 {
    height: 100%;
  }

  .no-underline {
    text-decoration: none;
  }

  .card-panel {
    min-width: 300px;
    height: 100%;
  }

  .identification {
    margin-top: -34px;
    margin-right: -34px;
    font-size: 60px !important;
    color: #274abb;
    opacity: 0.4;
  }

  .btn-next {
    opacity: 0.7;
    font-size: 24px !important;
  }

  .container-fundo {
    height: calc(100vh - 7vh) !important;
  }

</style>